import React from "react";
import PropTypes from 'prop-types';
import { default as NumberFormat } from "react-number-format";

export function NumberFormatPhoneCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      type="tel"
      mask=" "
      format="(###) ###-####"
      isNumericString
    />
  );
}

export function NumberFormatPhoneCustomTextOnly(props) {
  return NumberFormatPhoneCustom({
    displayType: "text",
    renderText: (v) => v,
    ...props,
  });
}

NumberFormatPhoneCustom.propTypes = {
  inputRef: PropTypes.any,
  onChange: PropTypes.func,
};

export default NumberFormatPhoneCustom;
