import React, { Suspense, useState } from 'react';
import './App.css';
import { CookiesProvider } from 'react-cookie';
import { HiveProvider } from "../services/HiveContext";
import { CircularProgress, makeStyles } from '@material-ui/core';
import SubDomainContext from '../contexts/SubDomainContext';
import Home from '../pages/Home';
import ErrorBoundary from './ErrorBoundary';

const useStyles = makeStyles(() => ({
  parent: {
    display: 'flex',
    'min-height': '100vh',
    'justify-content': 'center',
    'align-items': 'center',
  },
}));

function Spinner() {
  const styles = useStyles();
  return (
    <div className={styles.parent}>
      {<CircularProgress />}
    </div>
  );
}

const RESERVED_SUBDOMAINS = [
  'joinlogq',
  'logq',
  'www',
];

const getSubDomain = () => {
  const hostname = window.location.hostname;
  const comps = hostname.split('.');
  const subdomain = comps && comps.length > 1 ? comps[0] : undefined;
  return RESERVED_SUBDOMAINS.includes(subdomain)
    ? undefined
    : subdomain;
};

function App() {
  const [ subdomain, setSubDomain ] = useState(getSubDomain);

  // Trick, to make sure we have the correct VH (viewport) on mobile phone when they show header and footer bar.
  // Reference: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // We listen to the resize event
  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  window.addEventListener('hashchange', () => {
    const subdomain = getSubDomain();
    setSubDomain(subdomain);
  });

  return (
    <ErrorBoundary>
      <SubDomainContext.Provider value={subdomain}>
        <Suspense fallback={<Spinner />}>
          <HiveProvider>
            <div className="App">
              <CookiesProvider>
                <Home />
              </CookiesProvider>
            </div>
          </HiveProvider>
        </Suspense>
      </SubDomainContext.Provider>
    </ErrorBoundary>
  );
}

export default App;
