import Container from "@material-ui/core/Container";
import React, { useContext, useEffect, useState, useCallback } from 'react';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { useCookies } from "react-cookie";
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import WelcomePage from "./Welcome";
import CheckInPage from "./CheckIn";
import InitialPage from "./Initial";
import NearMePage from "./NearMe";
import ValidationPage from "./Validation";
import ResultPage from "./Result";
import BackgroundImageContainer from '../components/BackGroundImageContainer';
import LogoHeader from '../components/LogoHeader';
import { HiveContext } from "../services/HiveContext";
import { strings } from "../localizedStrings";
import 'moment/locale/fr';
import { appTheme } from './AppTheme';

import moment from "moment";
import SubDomainContext from "../contexts/SubDomainContext";
import { useChangeLanguage } from "../utils/cookies";

const useStyles = makeStyles((theme) => ({
  innerContainer: {
    padding: theme.spacing(0, 5)
  },
  topContainer: {
    padding:"0px 0px",
    width: "100%",
    height:"100vh",    // fallback for browsers that do not support custom properties
    // eslint-disable-next-line no-dupe-keys
    height: "calc(var(--vh, 1vh) * 100)"
  }
}));

export default function Home() {
  const classes = useStyles();
  const [cookies, setCookies] = useCookies(["language", "userInfo", "validated"]);
  const { location } = useContext(HiveContext);
  const [ , setLanguage ] = useState();
  const subdomain = useContext(SubDomainContext);
  const changeLanguageCookie = useChangeLanguage(setCookies);

  useEffect(
    () => {
      if (cookies.language) {
        strings.setLanguage(cookies.language);
        moment.locale(cookies.language);

        // This is needed to force a new render after the change
        setLanguage(cookies.language);
      } else if (location && location.defaultLanguage) {
        changeLanguageCookie(location.defaultLanguage);
      } else {
        // TODO: get default language from configuration
        changeLanguageCookie('fr');
      }

      if (!cookies.userInfo) {
        setCookies("userInfo", { name:"", phone:""});
      }
    },
    [location, cookies, setCookies, setLanguage, changeLanguageCookie]
  );

  const redirectToStart = useCallback(() => {
    let { protocol, hostname, port } = window.location;

    hostname = hostname.split('.').slice(1).join('.');
    port = port ? `:${port}` : '';

    const url = `${protocol}//${hostname}${port}`;
    window.location.assign(url);
  }, []);


  // If we have a subdomain, but we don't have a location, an invalid location was used
  // In this case, redirect to the base hostname
  if (subdomain && (!location || !location.name)) {
    redirectToStart();
    return null;
  }

  return (
    <BackgroundImageContainer style={{height:'100%'}}>
      <Container className={classes.topContainer}>
        <ThemeProvider theme={appTheme}>
          <LogoHeader  />
          <Container className={classes.innerContainer} maxWidth="sm">
            <Router>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => location && location.name
                    ? <Redirect to={'/welcome'} />
                    : <InitialPage />
                  }
                />
                <Route
                  path="/near"
                  render={() => location && location.name
                    ? <Redirect to={'/welcome'}/>
                    : <NearMePage />}
                />

                <Route
                  path="/checkin"
                  render={() => !location || !location.name
                    ? <Redirect to={'/'} />
                    : <CheckInPage />}
                />

                <Route
                  path="/validate"
                  render={() => !location || !location.name
                    ? <Redirect to={'/'} />
                    : <ValidationPage />
                  }
                />

                <Route
                  path="/result"
                  render={() => {
                    if (!location || !location.name) {
                      return <Redirect to={'/'} />;
                    } else if (cookies.validated) {
                      return <ResultPage />;
                    } else {
                      redirectToStart();
                      return null;
                    }
                  }}
                />

                <Route
                  path="/welcome"
                  render={() => !location || !location.name
                    ? <Redirect to={'/'} />
                    : <WelcomePage />
                  }
                />

                <Route path="*">
                  <Redirect to={'/welcome'} />
                </Route>
              </Switch>
            </Router>
          </Container>
        </ThemeProvider>
      </Container>
    </BackgroundImageContainer>
  );
}
