import LocalizedStrings from 'react-localization';

export let strings = new LocalizedStrings({
  en:{
    // eslint-disable-next-line no-template-curly-in-string
    safety: "At ${locationName} we care about your safety, and to help protect your health and safety, we have partnered with ${sponsorName} to provide LogQ.",
    patrons: "We ask all our patrons to check-in prior to entering.",
    simple: "It's simple enough, just enter your name and number to be verified, and you'll be welcomed in!",
    checkIn: "Check-in",
    enterInfo: "Please enter the following information to check-in.",
    // eslint-disable-next-line no-template-curly-in-string
    accurateInfo: "Please ensure this information is accurate as it will be used to verify your details to gain entry. None of this information will be shared with this establishment or ${sponsorName}, it will only be made available to public health officials in the event of spread event.",
    name: "Name",
    phone: "Phone",
    remember: "Remember me next time",
    confirm: "I confirm that I do not have any COVID-19 symptoms or signs, and I have not been in close contact with anyone suffering from a respiratory illness or with a confirmed or possible case of COVID-19.",
    continue: "Continue",
    enterFullName: "please enter your full name",
    enterPhone: "please enter your phone number",
    sent: "We have sent you a text message with a validation code, enter it below to complete check-in.",
    enterCode: "Enter your code",
    noCode: "Didn't get a code?",
    resendCode: "Resend code now",
    // eslint-disable-next-line no-template-curly-in-string
    thankYou: "Thank you for registering to enter ${locationName}.",
    showScreen: "Show this screen to the folks at the door to gain entry.",
    controlSpread: "To help control the potential spread of COVID-19 you can register with LogQ to gain entry into partnered venues.",
    register: "Registering with LogQ is simple enough, select the venue you are visiting, enter your name and number, verify it, and you'll be welcomed in!",
    welcome: "Welcome to LogQ patron registry",
    letsGo: "OK! Let's go!",
    nearYou: "Partnered venues near you",
    tap: "Tap a venue to check-in.",
    otherLanguage: "Version française",
    oops: "Oops",
    wrongCode: "The code entered was not recognized.",
    checkCode: "Check the code that was sent via text message.",
    tryAgain: "Try Again",
    newCode: "Alternatively, you can try again with a new code.",
    getNewCode: "Get a New Code",
    // eslint-disable-next-line no-template-curly-in-string
    phoneNumberSent: "We sent the code to ${phoneNumber}. If this is not your number, please check your details and check-in again.",
    checkDetails: "Check Details",
    searchVenuesPlaceholder: "Search venues",
  },
  fr: {
    // eslint-disable-next-line no-template-curly-in-string
    safety: "Chez ${locationName}, nous nous soucions de votre sécurité et, pour vous aider à protéger votre santé et votre sécurité, nous avons conclu un partenariat avec ${sponsorName} pour fournir LogQ.",
    patrons: "Nous demandons à tous nos clients de s'enregistrer avant d'entrer.",
    simple: "C'est assez simple, entrez simplement votre nom et votre numéro pour être vérifié, et vous êtes les bienvenus!",
    checkIn: "Enregistrement",
    enterInfo: "Veuillez saisir les informations suivantes pour vous enregistrer.",
    // eslint-disable-next-line no-template-curly-in-string
    accurateInfo: "Veuillez vous assurer que ces informations sont exactes car elles seront utilisées pour vérifier vos coordonnées afin d'obtenir le droit d'entrer. Aucune de ces informations ne sera communiquée à cet établissement ou à ${sponsorName}, elles ne seront mises à la disposition des responsables de la santé publique qu'en cas de propagation.",
    name: "Nom",
    phone: "Téléphone",
    remember: "Se rappeller de moi",
    confirm: "Je confirme que je ne présente aucun symptôme ou signe de COVID-19, et que je n'ai pas été en contact étroit avec une personne souffrant d'une maladie respiratoire ou avec un cas confirmé ou possible de COVID-19.",
    continue: "Continuer",
    enterFullName: "veuillez entrer votre nom",
    enterPhone: "veuillez entrer votre numéro de téléphone",
    sent: "Nous vous avons envoyé un SMS avec un code de validation, entrez-le ci-dessous pour terminer l'enregistrement.",
    enterCode: "Entrez votre code",
    noCode: "Vous n'avez pas reçu de code?",
    resendCode: "Renvoyer le code maintenant",
    // eslint-disable-next-line no-template-curly-in-string
    thankYou: "Merci de vous être inscrit pour participer ${locationName}.",
    showScreen: "Montrez cet écran aux gens à la porte pour entrer.",
    controlSpread: "Pour aider à contrôler la propagation potentielle de COVID-19, vous pouvez vous inscrire auprès de LogQ afin d'obtenir l'accès aux sites partenaires.",
    register: "S'inscrire à LogQ est assez simple, sélectionnez le lieu que vous visitez, entrez votre nom et votre numéro, vérifiez-le et vous serez accueilli!",
    welcome: "Bienvenue à LogQ registre des clients",
    letsGo: "OK! Allons-y!",
    nearYou: "Sites partenaires près de chez vous",
    tap: "Tapez sur un lieu pour vous enregistrer.",
    otherLanguage: "English version",
    oops: "Désolé",
    wrongCode: "Le code inséré n'est pas reconnu.",
    checkCode: "Vérifiez qu'il n'y ait pas d'erreur dans le code.",
    tryAgain: "Réessayer",
    newCode: "Il est aussi possible de réessayer avec un nouveau code.",
    getNewCode: "Recevoir un autre code",
    // eslint-disable-next-line no-template-curly-in-string
    phoneNumberSent: "Le code a été envoyé au ${phoneNumber}. Si ce n'est pas votre numéro, veuillez vérifier vos détails.",
    checkDetails: "Vérifier mes détails",
    searchVenuesPlaceholder: "Trouver un partenaire",
  }
});
