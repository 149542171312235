import { createMuiTheme } from '@material-ui/core/styles';


export const appTheme = createMuiTheme({
  palette: {
    action: {
      disabledBackground: 'rgb(5,63,107)',
      disabled: 'rgb(160, 172, 180)'
    },
    primary: {
      main: 'rgb(7,122,211)',
    },
    secondary: {
      main: 'rgb(56,190,239)'
    },
    textPrimary: {
      main: 'rgb(228,228,227)'
    },
    textSecondary: {
      main: 'rgb(160,160,160)'
    },
  },
  typography: {
    h5: {
      color:"white",
    },
    subtitle1: {
      color:"white",
      lineHeight: 1.2
    }
  },
  overrides: {
    MuiTextField: {
      root: {
        background: 'transparent !important',
      },
    },
    MuiInputBase: {
      input: {
        background: 'white !important',
        padding: '10px !important'
      }
    },
    MuiInputLabel:{
      root: {
        color: 'white',
        fontWeight: 'bold'
      }
    },
    MuiIconButton: {
      label: {
        background: 'white !important',
        height: '16px !important',
        width: '16px !important'
      }
    },
    MuiButtonContained: {
      MuiDisabled: {
        label: {
          fontWeight: 'bold',
        },
        root: {
          backgroundColor: 'rgb(5,63,107) !important'
        }
      },
    },
  },
});


