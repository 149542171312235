import React, { useContext } from "react";

import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import SuccessImg  from '../assets/success.png';
import Divider from '@material-ui/core/Divider';
import moment from "moment";
import { useCookies } from 'react-cookie';
import { HiveContext } from "../services/HiveContext";
import { strings } from "../localizedStrings";

const useStyles = makeStyles((theme) => ({
  txt: {
    fontWeight: '500',
    textAlign: 'center',
    color: 'white',
  },
  innerContainer: {
    padding: theme.spacing(0, 5)
  },
  titleTxt: {
    textAlign: 'center',
    fontSize: '30px',
    color: 'white',
    fontWeight:'500'
  },
  dvd: {
    background: 'white',
  }
}));

export default function Result() {
  const { location } = useContext(HiveContext);
  const classes = useStyles();
  const [cookies] = useCookies(["language","userInfo"]);

  moment.locale(cookies.language);
  const day = moment().format("dddd");
  const datestamp = moment().format("MMM DD, YYYY");
  const timestamp = moment().format("H:mm:ss");

  return (
    <>
      <Grid container direction="column" justify="center">
        <Grid item md>
          <Typography className={classes.txt} variant="subtitle1">
            {
              // eslint-disable-next-line  no-template-curly-in-string
              strings.thankYou.replace("${locationName}", location.name)
            }
          </Typography>
        </Grid>

        <Grid style={{margin:'10px 0px 40px 0'}} item md>
          <Typography  className={classes.txt} variant="subtitle1">{strings.showScreen}</Typography>
        </Grid>

        <Grid style={{marginBottom:'40px'}} container justify="center">
          <img src={SuccessImg} alt="success" style={{height:'150px', width:'auto'}} />
        </Grid>

        <Grid item md>
          <Typography style={{fontStyle: 'italic'}} className={classes.titleTxt}>{cookies.userInfo.name}</Typography>
        </Grid>

        <Divider className={classes.dvd} />

        <Grid container justify="center" >
          <Typography className={classes.titleTxt} variant="h1">{day}</Typography>
        </Grid>

        <Grid container justify="center" >
          <Typography className={classes.titleTxt} variant="h1">{datestamp}</Typography>
        </Grid>

        <Grid container  justify="center">
          <Typography className={classes.titleTxt} variant="h1">{timestamp}</Typography>
        </Grid>

      </Grid>

    </>
  );
}
