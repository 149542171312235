import React, { useEffect, useState, useContext } from "react";
import PropTypes from 'prop-types';
import { useThrowableObservable } from "../utils/Hooks";
import ConfigService from "./ConfigService";
import HiveConfigContext from '../contexts/HiveConfigContext';
import { ConnectionService } from 'hive-client-utils';
import { client } from 'traceq-client-lib';
import { ReplaySubject } from "rxjs";
import SubDomainContext from "../contexts/SubDomainContext";

export const HiveContext = React.createContext();

function InternalHiveProvider({ children, location$ }) {
  const connection = useThrowableObservable(ConnectionService.connection$);
  const location = useThrowableObservable(location$);

  return (
    <HiveContext.Provider value={{ bee: connection.bee, location }}>
      {children}
    </HiveContext.Provider>
  );
}

InternalHiveProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  location$: PropTypes.object.isRequired,
};

const connectAndFetchLocation = async (hiveConfig, location$, subdomain) => {
  try {
    const bee = await ConnectionService.signInAsBee(
      hiveConfig,
      process.env.REACT_APP_BEE_NAME,
      process.env.REACT_APP_BEE_PASS
    );

    if (subdomain) {
      const location = await client.common.getLocationBySubdomain(bee, subdomain);
      location$.next(location);
    } else {
      location$.next({});
    }
  } catch (error) {
    console.error('Could not load location info', error);

    location$.next({});
  }
};

export function HiveProvider({ children }) {
  const hiveConfig = useThrowableObservable(ConfigService.config$);
  const [ location$ ] = useState(() => new ReplaySubject(1));
  const subdomain = useContext(SubDomainContext);

  useEffect(
    () => {
      connectAndFetchLocation(hiveConfig, location$, subdomain);
      return () => ConnectionService.releaseBee();
    },
    [hiveConfig, location$, subdomain]
  );

  return (
    <HiveConfigContext.Provider value={hiveConfig}>
      <InternalHiveProvider location$={location$} >
        {children}
      </InternalHiveProvider>
    </HiveConfigContext.Provider>
  );
}

HiveProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
};
