import _ from 'lodash';

const KNOWN_DOMAINS = [
  'joinlogq.com',
  'logq.m21lab.com',
  'localhost',
];

export function getDomain() {
  const { hostname } = window.location;

  for (let knownDomain of KNOWN_DOMAINS) {
    if (_.endsWith(hostname, knownDomain)) {
      return  knownDomain;
    }
  }

  return hostname;
}