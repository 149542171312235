const _ = require('lodash');

const assertBee = (bee) => {
  if (!bee && !bee.info) {
    throw new Error('Need a connected bee!');
  }
};

const getReactionUuid = r => _.get(r, 'details.uuid');

const getReactionMessage = r => _.get(r, 'details.message');

const cleanupStats = (stats) => {
  // Because of a Honey issue, hourly stats are keyed as '_<number>', we need to
  // remove the '_'
  const hourlies = _.get(stats, 'hourly', {});
  stats.hourly = _.mapKeys(hourlies, (_v, k) => parseInt(_.replace(k, /_/g, '')));

  // Same for the validatedPeopleByHour
  const validatedPeopleByHour = _.get(stats, 'validatedPeopleByHour', {});
  stats.validatedPeopleByHour = _.mapKeys(validatedPeopleByHour, (_v, k) => parseInt(_.replace(k, /_/g, '')));

  return stats;
};

module.exports = {
  assertBee,
  getReactionUuid,
  getReactionMessage,
  cleanupStats,
};
