import _ from 'lodash';
import { getDomain } from "./urls";
import { MAX_AGE_USER_INFO } from "./constants";
import { useCallback } from "react";

export function useChangeLanguage(setCookies) {
  return useCallback(
    (language) => {
      const options = {
        path: "/",
        maxAge: MAX_AGE_USER_INFO,
      };

      // Set the domain, expect for localhost, as set-cookie does not support it
      const domain = getDomain();
      if (domain && domain !== 'localhost') {
        _.assignIn(options, { domain });
      }

      setCookies("language", language, options);
    },
    [ setCookies ]
  );
}

export function useLanguageChangeCallback(cookies, setCookies) {
  const changeLanguage = useChangeLanguage(setCookies);

  return useCallback(() => {
    if (cookies.language === 'en') {
      changeLanguage('fr');
    }
    else {
      changeLanguage('en');
    }
  }, [ cookies.language, changeLanguage ]);
}
