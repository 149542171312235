import React from 'react';
import PropTypes from 'prop-types';

export default function Error({ error, classNameOverride = 'loader-background' }) {
  return (
    <div className={classNameOverride}>
      <div className="loader-main">
        {
          error &&
          <h4 className="loader-error">{error.message || error}</h4>
        }
        {
          // TODO: localize
          !error &&
          <h4 className="loader-error">{"Error loading application"}</h4>
        }
      </div>
    </div>
  );
}

Error.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  classNameOverride: PropTypes.string,
};
