import React, { useContext, useState, useCallback, useEffect, useRef } from "react";
import Checkbox from '@material-ui/core/Checkbox';

import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import { HiveContext } from "../services/HiveContext";
import { useHistory } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { strings } from "../localizedStrings";
import { useThrowableObservable, useAsyncCallback } from "../utils/Hooks";
import { ConnectionService } from "hive-client-utils";
import { client } from "traceq-client-lib";
import NumberFormatPhoneCustom from "../components/NumberFormatPhoneCustom";
import { MAX_AGE_VALIDATED, MAX_AGE_USER_INFO } from "../utils/constants";
import HealthDefinesUs from "../components/HealthDefinesUs";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: 'white'
  },
  txt: {
    fontWeight: '500',
    textAlign: 'center',
    color: 'white',
  },
  switchContainer: {
    padding: theme.spacing(0.5, 0.5, 4, 0.5) + '!important',
    flexWrap: 'nowrap'
  },
  itemContainer: {
    padding: '0 !important'
  },
  footer: {
    'backdrop-filter': 'blur(5px)',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))',
    position: 'absolute',
    right: 0,
    bottom: 0,
    padding: theme.spacing(2, 1, 2, 1) + '!important'
  }
}));

export default function Checkin() {
  const connection = useThrowableObservable(ConnectionService.connection$);
  const { location } = useContext(HiveContext);
  const [cookies, setCookies, removeCookies] = useCookies(["userInfo", "personId", "validated"]);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [confirm, setConfirm] = useState(false);
  const history = useHistory();
  const buttonRef = useRef();
  const paddingRef = useRef();
  const [ paddingHeight, setPaddingHeight ] = useState(0);

  useEffect(() => {
    if (cookies.userInfo && cookies.userInfo.name) {
      setName(cookies.userInfo.name);
    }
    if (cookies.userInfo && cookies.userInfo.phone) {
      setPhone(cookies.userInfo.phone);
    }
  }, [cookies.userInfo]);

  const classes = useStyles();

  const isContinueButtonDisabled = useCallback(() => {
    // Disable the start button if no language is selected
    return  !confirm || !name || !phone || phone.length !== 10;
  }, [name, phone, confirm]);

  const handleClick = useAsyncCallback(async () => {
    if (connection && connection.bee) {
      const result = await client.person.addPerson(connection.bee, location.id, name, phone, cookies.language);

      setCookies("userInfo", {name, phone}, { path: "/", maxAge: MAX_AGE_USER_INFO });
      if (result.needsValidation) {
        removeCookies("validated");
        setCookies('personId', result.id);
        history.push('/validate');
      } else {
        setCookies("validated", true, { path: "/", maxAge: MAX_AGE_VALIDATED });
        history.push('/result');
      }
    }
  }, [connection, cookies.language, setCookies, location, name, phone]);

  const handleCheckboxChange = useCallback(() => {
    setConfirm(!confirm);
  }, [confirm]);

  useEffect(
    () => {
      if (!paddingRef || !paddingRef.current || !buttonRef || !buttonRef.current) {
        return;
      }

      setPaddingHeight(buttonRef.current.getBoundingClientRect().height * 1.25);
    },
    [buttonRef, paddingRef]
  );

  return (
    <>
      <Grid container direction="column" justify="center" spacing={3}>
        <Grid item className={classes.itemContainer} xs >
          <Typography style={{marginTop: '30px'}} className={classes.txt} variant="subtitle1">{strings.enterInfo}</Typography>
        </Grid>

        <Grid item className={classes.itemContainer} xs >
          <Typography style={{textAlign: 'left', color: 'white',marginTop: '10px', fontStyle: 'italic', marginBottom: '20px'}}  variant="subtitle1">
            {
              // eslint-disable-next-line no-template-curly-in-string
              strings.accurateInfo.replace("${sponsorName}", location.sponsorName)
            }
          </Typography>
        </Grid>

        <Grid item className={classes.itemContainer} xs >
          <TextField  className={classes.textField} required placeholder={strings.enterFullName} label={strings.name} variant="standard" fullWidth margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            autoComplete="name"
            value={name}
            style={{marginBottom:"15px"}}
            onChange={e => setName(e.target.value)} />
        </Grid>


        <Grid item className={classes.itemContainer} xs >
          <TextField  className={classes.textField}  required placeholder={strings.enterPhone} label={strings.phone} variant="standard" fullWidth margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputComponent: NumberFormatPhoneCustom,
            }}
            autoComplete="tel"
            value={phone}
            style={{marginBottom:"15px"}}
            onChange={e => setPhone(e.target.value)} />
        </Grid>


        <Grid className={classes.switchContainer} container direction="row" justify="flex-start" spacing={1}>
          <Grid item >
            <Checkbox
              color="primary"
              checked={confirm}
              onChange={handleCheckboxChange}
            />
          </Grid>
          <Grid style={{alignSelf:'center'}} item >
            <Typography style={{color: 'white',fontStyle: 'italic', fontWeight: 'bold'}} variant="subtitle2">{strings.confirm}</Typography>
          </Grid>
        </Grid>

        <div ref={paddingRef} style={{ minHeight: paddingHeight }}></div>

        <Grid ref={buttonRef} className={classes.footer} container direction="column" alignItems="center" justify="center">
          <Button disabled={isContinueButtonDisabled()} onClick={handleClick} size="large" style={{textTransform: 'none'}} variant="contained" color="primary">
            {strings.continue}
          </Button>

          <HealthDefinesUs />
        </Grid>

      </Grid>
    </>
  );
}
