import _ from 'lodash';
import { ReplaySubject } from 'rxjs';

export const config$ = new ReplaySubject(1);

// Build a config from the given parameters. If we are self-serve, we will have to
// connect to the master org to validate further
const CONFIG = _.pickBy({
  host: process.env.REACT_APP_HIVE_HOST,
  orgId: process.env.REACT_APP_HIVE_ORG_ID,
  appId: process.env.REACT_APP_HIVE_APP_ID,
  categories: [ 'storage', 'core', 'invocation' ],

  // Adding a validate object will ensure that the token is verified when connecting
  validation: {
    rolesToCheck: [ 'everyone' ],
  }
});

let promise = Promise.resolve(CONFIG);

// TODO_SELF_SERVE: bring the code from screener here

promise
  .then(config => config$.next(config))
  .catch(error => config$.error(error))
;

const ConfigService = {
  config$,
};

export default ConfigService;
