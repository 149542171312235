const _ = require('lodash');
const {
  assertBee,
  getReactionMessage,
  cleanupStats,
} = require('./utils');
const {waitForInvocationReaction} = require('hive-client-utils').utils;
const stats = require('./stats');

/**
 * @name admin
 * @memberof client
 */

/**
 * @function getMyLocation
 * @memberof admin
 *
 * @description Retrieves the location for the connection user
 * @access Accessible by the AdminUser
 *
 * @param {Object} bee - the bee to connect with.
 *
 * @returns {Promise<Array>} - A promise that resolves to the location on success, or is rejected on failure.
 */
function getMyLocation(bee) {
  assertBee(bee);
  return bee.storage.getClassInstancesByClassName('traceq', 'location')
    .then(locations => _.first(locations))
    .then(location => _.assignIn(
      { id: location.id },
      _.get(location, 'properties', {})
    ))
  ;
}

/**
 * @function findPeopleByDate
 * @memberof admin
 *
 * @description Retrieves an anonymized list of people added between given dates.
 * @access Accessible by the AdminUser
 *
 * @param {Object} bee - the bee to connect with.
 * @param {String} locationId - the id of the location
 * @param {String} minDate - an ISO-8601 minimum date.
 * @param {String} maxDate - an ISO-8601 minimum date.
 *
 * @returns {Promise<Array>} - A promise that resolves to a list of people on success, or is rejected on failure.
 */
function findPeopleByDate(bee, locationId, minDate, maxDate) {
  assertBee(bee);
  return bee.actions.invoke('traceq.findPeopleByDate', locationId, minDate, maxDate)
    .then(waitForInvocationReaction(bee, getReactionMessage))
  ;
}

/**
 * @function reportPeopleByDate
 * @memberof admin
 *
 * @description Generates a report of the people added between given dates and sends it to pre-configured recipients.
 * @access Accessible by the AdminUser
 *
 * @param {Object} bee - the bee to connect with.
 * @param {String} locationId - the id of the location
 * @param {String} language - the language the report will be generated in.
 * @param {String} minDate - an ISO-8601 minimum date.
 * @param {String} maxDate - an ISO-8601 minimum date.
 *
 * @returns {Promise<Array>} - A promise that resolves on success, or is rejected on failure.
 */
function reportPeopleByDate(bee, locationId, language, minDate, maxDate) {
  assertBee(bee);
  return bee.actions.invoke('traceq.reportPeopleByDate', locationId, language, minDate, maxDate)
    .then(waitForInvocationReaction(bee))
  ;
}

/**
 * @function fetchStats
 * @memberof admin
 *
 * @description Fetchs stats needed for graphs
 * @access Accessible by the AdminUser
 *
 * @param {Object} bee - the bee to connect with.
 * @param {String} locationId - the id of the location
 *
 * @returns {Promise<Array>} - A promise that resolves with a stats object on success, or is rejected on failure.
 */
function fetchStats(bee, locationId) {
  assertBee(bee);
  return bee.actions.invoke('traceq.fetchStats', locationId)
    .then(waitForInvocationReaction(bee, getReactionMessage))
    .then(cleanupStats)
  ;
}

/**
 * @function observeStats
 * @memberof admin
 *
 * @description Builds an observable that is updated when stats are refresh in the backend
 * @access Accessible by the AdminUser
 *
 * @param {Object} bee - the bee to connect with.
 * @param {String} locationId - the id of the location
 *
 * @returns {Observable} - A observable that can be subscribed to get stats updates
 */
function observeStats(bee, locationId) {
  assertBee(bee);
  return stats.observe(bee, locationId);
}

/**
 * @function notifyPeople
 * @memberof admin
 *
 * @description Notifies patrons
 * @access Accessible by the AdminUser
 *
 * @param {Object} bee - the bee to connect with.
 * @param {String} locationId - the id of the location
 * @param {String} minDate - an ISO-8601 date for the start of the query
 * @param {String} maxDate - an ISO-8601 date for the end of the query
 *
 * @returns {Promise<Array>} - A promise that resolves with a stats object on success, or is rejected on failure.
 */
function notifyPeople(bee, locationId, minDate, maxDate) {
  assertBee(bee);
  return bee.actions.invoke('traceq.notifyPeople', locationId, minDate, maxDate)
    .then(waitForInvocationReaction(bee, getReactionMessage))
  ;
}

/**
 * @function getNotifyInfo
 * @memberof admin
 *
 * @description Returns information about a possible notification to registered people.
 * @access Accessible by the AdminUser
 *
 * @param {Object} bee - the bee to connect with.
 * @param {String} locationId - the id of the location
 * @param {String} minDate - an ISO-8601 date for the start of the query
 * @param {String} maxDate - an ISO-8601 date for the end of the query
 *
 * @returns {Promise<Array>} - A promise that resolves with a stats object on success, or is rejected on failure.
 */
function getNotifyInfo(bee, locationId, minDate, maxDate) {
  assertBee(bee);
  return bee.actions.invoke('traceq.getNotifyInfo', locationId, minDate, maxDate)
    .then(waitForInvocationReaction(bee, getReactionMessage))
  ;
}

module.exports = {
  getMyLocation,
  findPeopleByDate,
  reportPeopleByDate,
  fetchStats,
  observeStats,
  notifyPeople,
  getNotifyInfo,
};
