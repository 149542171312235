/**
 * @namespace client
 */
module.exports = {
  common: require('./common'),
  setup: require('./setup'),
  provisioner: require('./provisioner'),
  admin: require('./admin'),
  person: require('./person'),
};
