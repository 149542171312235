import { makeStyles } from "@material-ui/core";
import React from "react";
import { useCookies } from "react-cookie";
import HealthDefinesUs_en from '../assets/mac-HealthDefinesUs-en.svg';
import HealthDefinesUs_fr from '../assets/mac-HealthDefinesUs-fr.svg';

const useStyles = makeStyles(() => ({
  maclogo: {
    marginTop: '16px',
    width: '70%',
    maxWidth: '350px'
  },
}));

export default function HealthDefinesUs() {
  const classes = useStyles();
  const [ cookies ] = useCookies('language');
  const macLogo = cookies.language === 'fr' ? HealthDefinesUs_fr : HealthDefinesUs_en;

  return (
    <img src={macLogo} alt="Macadamian Logo" className={classes.maclogo} />
  );
}
