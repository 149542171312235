const { assertBee, getReactionMessage } = require('./utils');
const {waitForInvocationReaction} = require('hive-client-utils').utils;

/**
 * @name provisioner
 * @memberof client
 */

/**
 * @function prepareLocation
 * @memberof provisioner
 *
 * @description Creates or updates a location
 * @access Accessible by ProvisioningUser role only
 *
 * @param {Object} bee - the bee to connect with.
 * @param {Object} props - the properties of the location
 * @param {String} props.name - (required) the name of the location, which appears in the UI and in messages
 * @param {String} props.subdomain - (required) the sub-domain of the location
 * @param {String} props.sponsorName - the name of the sponsor of the application
 * @param {String} props.timezone - the timezone of the location (ex: 'America/New_York)
 * @param {String} props.contactName - the name of the contact for the location
 * @param {String} props.contactEmail - the email of the contact for the location
 * @param {String} props.contactPhone - the phone number of the contact for the location
 * @param {String} props.address - the address of the location
 * @param {String} props.latitude - the latitude of the location
 * @param {String} props.longitude - the longitude of the location
 * @param {String} props.supportedLanguages - (required) an array of supported languages codes. Example: [ 'en', 'fr' ]
 * @returns {Promise<Object>} - A promise that resolves on success, or is rejected on failure. The returned object will
 * have the blobIds for the logo and the sponsorLogo (if sponsorName was specified)
 */
function prepareLocation(bee, props) {
  assertBee(bee);
  return bee.actions.invoke('traceq.prepareLocation', props)
    .then(waitForInvocationReaction(bee, getReactionMessage))
  ;
}

/**
 * @function uploadLogo
 * @memberof provisioner
 *
 * @description Uploads an image file.
 * @access Accessible by ProvisioningUser role on Node.js only
 *
 * @param {Object} bee - the bee to connect with.
 * @param {Object} fs - the node fs implementation. This is needed in order to be compatible with browsers.
 * @param {String} filename - the file to upload.
 * @param {Object} blobId - the id where to upload the file
 *
 * @returns {Promise<String>} - A promise that resolves on success, or is rejected on failure.
 */
function uploadLogo(bee, fs, filename, blobId) {
  return bee.blob.upload(fs, blobId, filename);
}

/**
 * @function activateLocation
 * @memberof provisioner
 *
 * @description Activate a location
 * @access Accessible by ProvisioningUser role on Node.js only
 *
 * @param {Object} bee - the bee to connect with.
 * @param {String} locationId - the ID of the location to activate
 * @param {String} adminPassword - the password for the admin user that will be created (using the contactEmail from
 * prepareLocation)
 *
 * @returns {Promise<String>} - A promise that resolves on success, or is rejected on failure.
 */
function activateLocation(bee, locationId, adminPassword) {
  assertBee(bee);
  return bee.actions.invoke('traceq.activateLocation', locationId, adminPassword)
    .then(waitForInvocationReaction(bee, () => {}))
  ;
}

module.exports = {
  prepareLocation,
  activateLocation,
  uploadLogo,
};
