import React, { useRef, useContext, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { HiveContext } from '../services/HiveContext';
import DefaultLogo from '../assets/logQ-mono.svg';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    width: 'auto',
    height: '70px',
  },
  toolbar: {
    display: 'block',
    height: '116px'
  },
  centeredAlign: {
    left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute'
  },
  bar: {
    position:'relative',
    background: 'transparent'
  },
  mTop: {
    marginTop:"20px"
  }
}));

export default function LogoHeader() {
  const classes = useStyles();
  const { bee, location } = useContext(HiveContext);
  const logoRef = useRef();

  useEffect(() => {
    async function fetchLogo() {
      if (bee && location.logo && logoRef.current) {
        let blob = await bee.blob.download(location.logo);

        // js blob for browser requires a different format
        let jsblob = new Blob([blob.content], {type:blob.contentType} );
        logoRef.current.src = URL.createObjectURL(jsblob);
      } else {
        logoRef.current.src = DefaultLogo;
      }
    }
    fetchLogo();
  }, [location, bee, logoRef]);

  return (
    <div className={classes.root}>
      <AppBar className={classes.bar} elevation={0} position="static" color="default" >
        <Toolbar className={classes.toolbar}>
          <Grid className={classes.mTop} container direction="row" justify='center'>
            <img ref={logoRef} alt="" className={classes.logo} />
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
