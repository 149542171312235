import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {  Link as RouterLink } from "react-router-dom";
import { strings } from "../localizedStrings";
import { Container } from '@material-ui/core';

/*
  * This component appears if the user enters the wring code on validation.
  * It offers different options as to how to proceed
*/

const useStyles = makeStyles((theme) => ({
  popup: {
    position: 'absolute',
    top: "15%",
    left: "5%",
    boxSizing: "border-box",
    borderRadius: "10px",
    width: "90%",
    height: "70%",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "rgba(0,0,0,0.7)",
    'backdrop-filter': 'blur(10px)',
    zIndex: 2,
    overflow: 'scroll'
  },
  noPopup: {
    display: "none"
  },
  titleGrid: {
    padding: theme.spacing(2,4) + '!important'
  },
  textGrid: {
    padding: theme.spacing(2, 2) + '!important'
  },
  buttonGrid: {
    padding: theme.spacing(0, 1, 3, 1) + '!important',
    borderRadius: "10px",
    marginRight: 'auto',
    marginLeft: "auto",
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    minWidth: "210px",
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  title: {
    textAlign: "center",
    fontStyle: "italic",
    fontSize: "30px",
    fontWeight: '500',
    color: "white"
  },
  errorMessage: {
    fontStyle: "italic",
    textAlign: "center",
    fontWeight: '500px',
    color: "white",
    paddingBottom: "12px"     
  },
  text: {
    textAlign: "center",
    fontWeight: '500px',
    color: "white",
  }
}));

export default function WrongCodeEnteredPopup(props) {

  const classes = useStyles();
  const showHidePopup = props.show ? classes.popup : classes.noPopup;

  const {hide, resendCode, phone} = props;

  const hideErrorMessage = useCallback(() => {
    hide('');
  }, [hide]);

  const resend = useCallback(() => {
    hideErrorMessage();
    resendCode();
  }, [resendCode, hideErrorMessage]);

  const formatPhoneNumber = useCallback(() => {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/,"($1) $2-$3");
  }, [phone]);

    return (
      <Container className={showHidePopup}>
        <Grid className={classes.titleGrid} item xs>
          <Typography className={classes.title}>{strings.oops}</Typography>
        </Grid>

      <Grid className={classes.textGrid} item xs>
        <Typography className={classes.errorMessage}>{strings.wrongCode}</Typography>
      </Grid>

      <Grid className={classes.textGrid} item xs>
        <Typography className={classes.text} variant="subtitle1">{strings.checkCode}</Typography>
      </Grid>

        <Grid className={classes.buttonGrid} container direction="row">
          <RouterLink to="/validate">
            <Button
            className={classes.button}
            size="large"
            style={{textTransform: 'none'}}
            variant="contained"
            color="primary"
            onClick={hideErrorMessage}>
              {strings.tryAgain}
            </Button>
          </RouterLink>
        </Grid>

        <Grid className={classes.textGrid} item xs>
          <Typography className={classes.text} variant="subtitle1">{strings.newCode}</Typography>
        </Grid>

        <Grid className={classes.buttonGrid} container direction="row">
          <RouterLink to="/validate">
            <Button
            className={classes.button}
            size="large"
            style={{textTransform: 'none'}}
            variant="contained"
            color="primary"
            onClick={resend}>
            {strings.getNewCode}
          </Button>
        </RouterLink>
      </Grid>

      <Grid className={classes.textGrid} item xs>
        <Typography className={classes.text} variant="subtitle1">
          {
            // eslint-disable-next-line no-template-curly-in-string
            strings.phoneNumberSent.replace("${phoneNumber}", formatPhoneNumber())
          }
        </Typography>
      </Grid>

        <Grid className={classes.buttonGrid} container direction="row">
          <RouterLink to="/checkin">
            <Button
            className={classes.button}
            size="large"
            style={{textTransform: 'none'}}
            variant="contained"
            color="primary"
            onClick={hideErrorMessage}>
            {strings.checkDetails}
          </Button>
        </RouterLink>
      </Grid>
    </Container>
  );
}

WrongCodeEnteredPopup.propTypes = {
  show: PropTypes.string.isRequired,
  hide: PropTypes.func.isRequired,
  resendCode: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
};
