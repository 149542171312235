const {
  assertBee,
  getReactionUuid
} = require('./utils');
const {waitForInvocationReaction} = require('hive-client-utils').utils;

/**
 * @name setup
 * @memberof client
 */

/**
 * @function initialize
 * @memberof setup
 *
 * @description Initializes the application
 * @access Accessible by SetupUser role only
 *
 * @param {Object} bee - the bee to connect with.
 * @param {Object} mailerConfig - a node mailer configuration object
 * @param {String} smsAccountSid - an account SID for the SMS service initialization
 * @param {String} smsAuthToken - an auth token for the SMS service initialization
 * @param {String} smsPhoneNumber - the sender phone number for the SMS service initialization
 *
 * @returns {Promise<String>} - A promise that resolves on success, or is rejected on failure.
 */
function initialize(bee, mailerConfig, smsAccountSid, smsAuthToken, smsPhoneNumber) {
  assertBee(bee);
  return bee.actions.invoke('traceq.init', mailerConfig, smsAccountSid, smsAuthToken, smsPhoneNumber)
    .then(waitForInvocationReaction(bee))
  ;
}

/**
 * @function setConfiguration
 * @memberof setup
 *
 * @description Creates or updates the configuration
 * @access Accessible by SetupUser role only
 *
 * @param {Object} bee - the bee to connect with.
 * @param {Object} props - the properties of the configuration
 * @param {String} props.baseUrl - the baseUrl for links in generated emails, etc
 *
 * @returns {Promise<String>} - A promise that resolves on success, or is rejected on failure.
 */
function setConfiguration(bee, props) {
  assertBee(bee);
  return bee.actions.invoke('traceq.setConfiguration', props)
    .then(waitForInvocationReaction(bee, getReactionUuid))
  ;
}

/**
 * @function addLocation
 * @memberof setup
 *
 * @description Creates or updates a location
 * @access Accessible by SetupUser role only
 *
 * @param {Object} bee - the bee to connect with.
 * @param {Object} props - the properties of the location
 * @param {String} props.name - (required) the name of the location, which appears in the UI and in messages
 * @param {String} props.subdomain - (required) the sub-domain of the location
 * @param {String} props.sponsorName - the name of the sponsor of the application
 * @param {String} props.timezone - the timezone of the location (ex: 'America/New_York)
 * @param {String} props.reportSender - the email of the sender for email reports.
 * @param {Array<String>} props.reportRecipients - an array of email that will receive reports.
 * @param {String} props.supportedLanguages - (required) an array of supported languages codes. Example: [ 'en', 'fr' ]
 * @param {Object} props.notificationMessage - (required) an object with the infection notification message for each
 * supported languages. Example: {
 *   'en': 'A person that was declared positive for COVID was at ${locationName} between ${startTime} and ${endTime}.',
 *   'fr': 'Une personne qui a été déclaré positive à la COVID fut à ${locationName} entre ${startTime} et ${endTime}.',
 * }
 * @param {String} adminName - the admin user name for the location
 * @param {String} adminPass - the adming user password for the location
 *
 * @returns {Promise<String>} - A promise that resolves on success, or is rejected on failure.
 */
function addLocation(bee, props, adminName, adminPass) {
  assertBee(bee);
  return bee.actions.invoke('traceq.addLocation', props, adminName, adminPass)
    .then(waitForInvocationReaction(bee, getReactionUuid))
  ;
}

/**
 * @function removeLocation
 * @memberof setup
 *
 * @description Removes a location
 * @access Accessible by SetupUser role on Node.js only
 *
 * @param {Object} bee - the bee to connect with.
 * @param {*} locationId  - the id of the location to remove
 *
 * @returns {Promise<String>} - A promise that resolves on success, or is rejected on failure.
 */
function removeLocation(bee, locationId) {
  assertBee(bee);
  return bee.actions.invoke('traceq.removeLocation', locationId)
    .then(waitForInvocationReaction(bee, getReactionUuid))
  ;
}

/**
 * @function uploadLocationLogo
 * @memberof setup
 *
 * @description Uploads and assigns an image file.
 * @access Accessible by SetupUser role on Node.js only
 *
 * @param {Object} bee - the bee to connect with.
 * @param {Object} fs - the node fs implementation. This is needed in order to be compatible with browsers.
 * @param {String} filename - the file to upload.
 * @param {String} locationId - the id of the location to upload for
 *
 * @returns {Promise<String>} - A promise that resolves on success, or is rejected on failure.
 */
function uploadLocationLogo(bee, fs, filename, locationId) {
  assertBee(bee);
  return bee.actions.invoke('traceq.uploadLocationLogo', locationId)
    .then(waitForInvocationReaction(bee, getReactionUuid))
    .then(blobId => bee.blob.upload(fs, blobId, filename))
  ;
}

/**
 * @function uploadSponsorLogo
 * @memberof setup
 *
 * @description Uploads and assigns an image file.
 * @access Accessible by SetupUser role on Node.js only
 *
 * @param {Object} bee - the bee to connect with.
 * @param {Object} fs - the node fs implementation. This is needed in order to be compatible with browsers.
 * @param {String} filename - the file to upload.
 * @param {String} locationId - the id of the location to upload for
 *
 * @returns {Promise<String>} - A promise that resolves on success, or is rejected on failure.
 */
function uploadSponsorLogo(bee, fs, filename, locationId) {
  assertBee(bee);
  return bee.actions.invoke('traceq.uploadSponsorLogo', locationId)
    .then(waitForInvocationReaction(bee, getReactionUuid))
    .then(blobId => bee.blob.upload(fs, blobId, filename))
  ;
}

/**
 * @function uploadLogo
 * @memberof setup
 *
 * @description Uploads an image file.
 * @access Accessible by SetupUser role on Node.js only
 *
 * @param {Object} bee - the bee to connect with.
 * @param {Object} fs - the node fs implementation. This is needed in order to be compatible with browsers.
 * @param {String} filename - the file to upload.
 *
 * @returns {Promise<String>} - A promise that resolves on success, or is rejected on failure.
 */
function uploadLogo(bee, fs, filename) {
  assertBee(bee);
  return bee.actions.invoke('traceq.uploadLogo')
    .then(waitForInvocationReaction(bee, getReactionUuid))
    .then(blobId => {
      return bee.blob.upload(fs, blobId, filename)
        .then(() => blobId);
    })
  ;
}

module.exports = {
  initialize,
  setConfiguration,
  addLocation,
  removeLocation,
  uploadLocationLogo,
  uploadSponsorLogo,
  uploadLogo,
};
