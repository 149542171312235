import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import TraceQBackground from '../assets/traceQ_background.png';

const useStyles = makeStyles(() => ({
  backgroundImageContainer: {
    backgroundImage: `url(${TraceQBackground})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    backgroundAttachment: 'fixed',
    overflowY: 'auto'
  }
}));

export default function BackgroundImageContainer(props) {
  const classes = useStyles();

  return (
    <div className={classes.backgroundImageContainer}>
      {props.children}
    </div>
  );
}

BackgroundImageContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
};
