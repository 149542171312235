const {
  assertBee,
  getReactionMessage,
} = require('./utils');
const {waitForInvocationReaction} = require('hive-client-utils').utils;
const _ = require('lodash');

const EARTH_RADIUS_METERS = 6378137;

const normalizeAcos = val => {
  if (val > 1) {
    return 1;
  }
  if (val < -1) {
    return -1;
  }
  return val;
};

const toRad = value => value * Math.PI / 180;

const getDistance = (lat1, long1, lat2, long2) => {
  let distanceInRads = Math.acos(
    normalizeAcos(
      Math.sin(toRad(lat2)) * Math.sin(toRad(lat1)) +
      Math.cos(toRad(lat2)) *
      Math.cos(toRad(lat1)) *
      Math.cos(toRad(long1) - toRad(long2))
    )
  );

  return Math.round(distanceInRads * EARTH_RADIUS_METERS);
};

const processLocations = (callerLocation) => (locations) => {
  if (!callerLocation) {
    return locations;
  }

  if (!callerLocation.latitude || !callerLocation.longitude) {
    return locations;
  }

  locations = _.map(locations, l => {
    return _.assignIn(
      {},
      l,
      { distance: getDistance(callerLocation.latitude, callerLocation.longitude, l.latitude, l.longitude) }
    );
  });
  locations = _.sortBy(locations, [ 'distance', 'name' ]);

  return locations;
};

/**
 * @name common
 * @memberof client
 */

/**
 * @function getLocations
 * @memberof common
 *
 * @description Retrieves all the locations.
 * @access Accessible by everyone
 *
 * @param {Object} bee - the bee to connect with.
 * @param {Object} callerLocation - an optional location (lat/long) to sort locations by distance
 * @param {Number} callerLocation.latitude - a latitude to sort by distance from
 * @param {Number} callerLocation.longitude - a longitude to sort by distance from
 *
 * @return {Promise<Array<Object>>} - A promise that resolves to a location objects or is rejected on failure.
 */
function getLocations(bee, callerLocation) {
  assertBee(bee);
  return bee.actions.invoke('traceq.getLocations')
    .then(waitForInvocationReaction(bee, getReactionMessage))
    .then(processLocations(callerLocation))
  ;
}

/**
 * @function getLocation
 * @memberof common
 *
 * @description Retrieves a specific location
 * @access Accessible by everyone
 *
 * @param {Object} bee - the bee to connect with.
 * @param {String} locationId - the id of the location to retrieve
 *
 * @return {Promise<Object>} - A promise that resolves to an object or is rejected on failure.
 */
function getLocation(bee, locationId) {
  assertBee(bee);
  return bee.actions.invoke('traceq.getLocation', locationId)
    .then(waitForInvocationReaction(bee, getReactionMessage))
  ;
}

/**
 * @function getLocation
 * @memberof common
 *
 * @description Retrieves a specific location
 * @access Accessible by everyone
 *
 * @param {Object} bee - the bee to connect with.
 * @param {String} subdomain - the subdomain of the location to find
 *
 * @return {Promise<Object>} - A promise that resolves to an object or is rejected on failure.
 */
function getLocationBySubdomain(bee, subdomain) {
  assertBee(bee);
  return bee.actions.invoke('traceq.findLocationBySubdomain', subdomain)
    .then(waitForInvocationReaction(bee, getReactionMessage))
  ;
}

/**
 * @function downloadLogo
 * @memberof common
 *
 * @description Downloads a given logo as a blob
 * @access Accessible by everyone
 *
 * @param {Object} bee - the bee to connect with.
 * @param {String} blobId - the blob ID of the logo to download.
 *
 * @returns {Object} - A promise that resolves to the downloaded image on success, or is rejected on failure.
 */
function downloadLogo(bee, blobId) {
  assertBee(bee);
  return bee.blob.download(blobId);
}

module.exports = {
  getLocations,
  getLocation,
  getLocationBySubdomain,
  downloadLogo,
};
