import React, { useEffect, useRef, useState } from "react";

import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';
import {  Link as RouterLink } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { strings } from "../localizedStrings";
import { useLanguageChangeCallback } from "../utils/cookies";
import HealthDefinesUs from "../components/HealthDefinesUs";

const useStyles = makeStyles((theme) => ({
  bigPadGrid: {
    padding: theme.spacing(4, 2) + '!important'
  },
  smallPadGrid: {
    padding: theme.spacing(2, 2) + '!important'
  },
  titleGrid: {
    padding: theme.spacing(2, 4) + '!important'
  },
  footer: {
    'backdrop-filter': 'blur(5px)',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))',
    position: 'absolute',
    right: 0,
    bottom: 0,
    padding: theme.spacing(2, 1, 2, 1) + '!important'
  },
  txt: {
    fontWeight: '500',
    textAlign: 'center',
    color: 'white',
  },
  title: {
    textAlign: "center",
    fontStyle: "italic",
    fontWeight: '300',
  }
}));

export default function Initial() {
  const [cookies, setCookies] = useCookies(["language"]);

  const classes = useStyles();

  const handleChangeLanguage = useLanguageChangeCallback(cookies, setCookies);

  const buttonRef = useRef();
  const paddingRef = useRef();
  const [ paddingHeight, setPaddingHeight ] = useState(0);

  useEffect(
    () => {
      if (!paddingRef || !paddingRef.current || !buttonRef || !buttonRef.current) {
        return;
      }

      setPaddingHeight(buttonRef.current.getBoundingClientRect().height * 1.25);
    },
    [buttonRef, paddingRef]
  );

  return (
    <>
      <Grid container direction="column" justify="center" spacing={3}>
        <Grid className={classes.titleGrid} item xs>
          <Typography className={classes.title} variant="h5">{strings.welcome}</Typography>
        </Grid>

        <Grid className={classes.smallPadGrid} item xs>
          <Typography  className={classes.txt} variant="subtitle1">{strings.controlSpread}</Typography>
        </Grid>

        <Grid className={classes.smallPadGrid} item xs>
          <Typography  className={classes.txt} variant="subtitle1">{strings.register}</Typography>
        </Grid>

        <Grid className={classes.smallPadGrid}   container direction="row" justify="center">
          <Link onClick={handleChangeLanguage} style={{fontWeight: 'bold'}} color="secondary" underline="always" href="#">{strings.otherLanguage}</Link>
        </Grid>
      </Grid>

      <div ref={paddingRef} style={{ minHeight: paddingHeight }}></div>

      <Grid ref={buttonRef} className={classes.footer} container direction="column" alignItems="center" justify="center" spacing={1}>
        <RouterLink to="/near">
          <Button
            size="large"
            style={{textTransform: 'none'}}
            variant="contained"
            color="primary">
            {strings.letsGo}
          </Button>
        </RouterLink>

        <HealthDefinesUs />
      </Grid>
    </>

  );
}
