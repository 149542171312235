const {
  assertBee,
  getReactionMessage
} = require('./utils');
const {waitForInvocationReaction} = require('hive-client-utils').utils;

/**
 * @name person
 * @memberof client
 */

/**
 * @function addPerson
 * @memberof person
 *
 * @description Adds a new person to the location
 * @access Accessible by everyone.
 *
 * @param {Object} bee - the bee to connect with.
 * @param {String} locationId - the location where to add the person
 * @param {String} name - the full name of the person
 * @param {String} phoneNumber - the phone number of the person
 * @param {String} language - the language selected by the person
 *
 * @returns {Promise<String>} - A promise that resolves to the new person ID, or is rejected on failure.
 */
function addPerson(bee, locationId, name, phoneNumber, language) {
  assertBee(bee);
  return bee.actions.invoke('traceq.addPerson', locationId, name, phoneNumber, language)
    .then(waitForInvocationReaction(bee, getReactionMessage))
  ;
}

/**
 * @function validatePerson
 * @memberof person
 *
 * @description Validate a person.
 * @access Accessible by everyone.
 *
 * @param {Object} bee - the bee to connect with.
 * @param {String} personId - the ID of the person to validate
 * @param {String} validationCode - the validation code that was received by SMS.
 *
 * @returns {Promise<String>} - A promise that resolves on success, or is rejected on failure.
 */
function validatePerson(bee, personId, validationCode) {
  assertBee(bee);
  return bee.actions.invoke('traceq.validatePerson', personId, validationCode)
    .then(waitForInvocationReaction(bee, r => r.details))
  ;
}

/**
 * @function resendCode
 * @memberof person
 *
 * @description Resends the validation code for a person, if possible.
 * @access Accessible by everyone.
 *
 * @param {Object} bee - the bee to connect with.
 * @param {String} personId - the ID of the person
 *
 * @returns {Promise<String>} - A promise that resolves on success, or is rejected on failure.
 */
function resendCode(bee, personId) {
  assertBee(bee);
  return bee.actions.invoke('traceq.resendCode', personId)
    .then(waitForInvocationReaction(bee))
  ;
}

module.exports = {
  addPerson,
  validatePerson,
  resendCode,
};
