import React, { useState, useCallback, useEffect } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { client } from 'traceq-client-lib';
import { useAsyncEffect, useThrowableObservable, useObservableState } from '../utils/Hooks';
import { watchGpsLocation, updateGpsLocationNow, usersGpsLocationHasBeenDetermined } from '../services/LocationService';

import { ConnectionService } from 'hive-client-utils';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ArrowForward from '@material-ui/icons/ArrowForward';
import _ from 'lodash';
import { strings } from "../localizedStrings";
import SearchLocation from "../components/SearchLocation";

/*
  * This is the default Search Radius. If no locations appear in this radius, none will show on the screen
  * To see the locations, Deactivate location services in the browser
  * Units of measurements: meters
*/
const DEFAULT_SEARCH_RADIUS = 5000;

const useStyles = makeStyles((theme) => ({
  bigPadGrid: {
    padding: theme.spacing(4, 2) + '!important'
  },
  smallPadGrid: {
    padding: theme.spacing(2, 2) + '!important'
  },
  titleGrid: {
    padding: theme.spacing(2, 4) + '!important'
  },
  txt: {
    fontWeight: '500',
    textAlign: 'left',
    color: 'white',
  },
  title: {
    textAlign: "center",
    fontStyle: "italic",
    fontWeight: '300',
  },
  list: {
    height: '40vh',
    overflow: 'auto',
    borderRadius: '6px 6px 0 0 !important',
    border: '2px solid grey !important',
    backgroundColor: "rgba(0,0,0,0.6)"
  },
  forward: {
    ':focus': {
      outline: '0 !important'
    },
    outline: '0 !important'
  }
}));

const ForwardButton = withStyles((theme) => ({
  label: {
    background: theme.palette.primary.main + '!important',
    height: '35px !important',
    width: '35px !important',
    borderRadius: '25%'
  },
}))(IconButton);

export default function NearMe() {
  const { bee } = useThrowableObservable(ConnectionService.connection$);
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [searchRadius, updateSearchRadius] = useState(DEFAULT_SEARCH_RADIUS);
  const [searchBarText, updateSearchBarText] = useState("");
  const gpsPosition = useObservableState(watchGpsLocation());

  const startToUpdateGpsLocation = useCallback(() => {
    updateGpsLocationNow();
  }, []);

  useEffect(() => {
    startToUpdateGpsLocation();
  }, [startToUpdateGpsLocation]);

  const classes = useStyles();

  useAsyncEffect(
    async () => {
      if (bee) {
        let position = undefined;
        if (usersGpsLocationHasBeenDetermined()) {
          position = gpsPosition;
        }
        const locations = await client.common.getLocations(bee, position);
        if (locations.length === 0) {
          console.error("ERROR: Unable to fetch locations ");
        } else {
          setLocations(locations);
        }
      }
    },
    [bee, gpsPosition]
  );

  const onGoToLocation = useCallback((i) => {

    if (!_.isEmpty(locations[i])) {
      var { protocol, hostname, port } = window.location;

      var newUrl = protocol + "//" + locations[i].subdomain + '.' + hostname + ":" + port + "/checkin";
      console.log("Redirecting to", newUrl);
      window.location.assign(newUrl);
    }
  }, [locations ]);

  const setSearchRadius = useCallback((event, value) => {
    //Minimum search radius is 100m
    if (value === 0) {
      updateSearchRadius(100);
    } else {
      updateSearchRadius(value);
    }
  }, [updateSearchRadius]);

  const updateSearchFilter = useCallback((event) => {
    updateSearchBarText(event.target.value);
  }, [updateSearchBarText]);

  /*
  * Here we filter the locations to only show the ones inside the searchRadius
  * Or based on the search input
  */
  useEffect(() => {
    let searchStringLength = searchBarText.length;

    const matchesNameAndLocation = (searchText, searchRadius) => (location) => {
      return _.includes(location.name.toLowerCase(), searchText.toLowerCase() && location.distance <= searchRadius);
    }

    const matchesName = (searchText) => (location) => {
      return _.includes(location.name.toLowerCase(), searchText.toLowerCase());
    }

    if (searchStringLength >= 3){
      if (gpsPosition.longitude && gpsPosition.latitude){
        setFilteredLocations(_.filter(locations, matchesNameAndLocation(searchBarText, searchRadius)));
      } else {
        setFilteredLocations(_.filter(locations, matchesName(searchBarText)));
      }
    } else {
      if (gpsPosition.longitude && gpsPosition.latitude){
        setFilteredLocations(_.filter(locations, location => location.distance <= searchRadius));
      } else {
        setFilteredLocations(locations);
      }
    }
  }, [searchRadius, gpsPosition, locations, searchBarText]);

  return (
    <>
      <Grid container direction="column" justify="center" spacing={3}>
        <Grid className={classes.titleGrid} item xs>
          <Typography className={classes.title} variant="h5">{strings.nearYou}</Typography>
        </Grid>
        <List className={classes.list} justify="center">
          {filteredLocations.map((location, index) => (
            <React.Fragment key={index}>
              <ListItem style={{justifyContent: 'space-between'}} onClick={() => onGoToLocation(index)}>
                <Typography style={{fontWeight: 'bold'}} className={classes.txt} variant="subtitle1">{location.name}</Typography>
                <ForwardButton  aria-label="forward" className={classes.forward} size="small">
                  <ArrowForward style={{height: '30px', width: '30px', color: 'white'}} fontSize="inherit" />
                </ForwardButton>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
        <SearchLocation 
          handleSearchChange={updateSearchFilter} 
          handleSearchRadiusChange={setSearchRadius}
          locationAllowed={(gpsPosition) && (gpsPosition.longitude !== 0) && (gpsPosition.latitude !== 0)}
          currentSearchRadius={searchRadius}
          />
        <Grid className={classes.smallPadGrid}  container direction="row" justify="center">
          <Typography style={{fontStyle: 'italic'}} className={classes.txt} variant="subtitle2">{strings.tap}</Typography>          
        </Grid>
      </Grid>
    </>
  );
}
