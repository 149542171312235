import React, { useState, useCallback } from "react";

import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import OtpInput from 'react-otp-input';
import { useHistory } from "react-router-dom";
import { strings } from "../localizedStrings";
import { useThrowableObservable, useAsyncCallback } from "../utils/Hooks";
import { ConnectionService } from "hive-client-utils";
import { client } from "traceq-client-lib";
import { useCookies } from "react-cookie";
import { MAX_AGE_VALIDATED } from "../utils/constants";
import WrongCodeEnteredPopup from "../components/WrongCodePopup";

const useStyles = makeStyles((theme) => ({
  txt: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'white',
    width: '100%',
  },
  innerContainer: {
    padding: theme.spacing(0, 5)
  },
  linkContainer: {
    padding:theme.spacing(0.5, 0.5, 4, 0.5) + '!important'
  },
  link: {
    fontWeight: 'bold',
    fontStyle: 'italic',
    textAlign: 'center'
  },
  itemContainer: {
    padding: '0 !important'
  },
  buttonContainer: {
    padding: theme.spacing(4, 1, 2, 1) + '!important'
  }
}));

export default function Validation() {
  const connection = useThrowableObservable(ConnectionService.connection$);
  const [ cookies, setCookies, removeCookies ] = useCookies('personId', 'validated');
  const [code, setCode] = useState('');
  const [validationError, setValidationError] = useState('');
  const classes = useStyles();
  const history = useHistory();
  const matches = useMediaQuery('(max-width:300px)');

  const handleChangeCode = useCallback((event) => {
    setCode(event);
  }, []);

  const handleValidateClick = useAsyncCallback(async () => {
    if (connection && connection.bee && cookies.personId) {
      try {
        await client.person.validatePerson(connection.bee, cookies.personId, code);
        setCookies("validated", true, { path: "/", maxAge: MAX_AGE_VALIDATED });
        removeCookies('personId');
        history.push('/result');
      } catch (e) {
        removeCookies('validated');
        setValidationError(e.message);
      }
    }
  }, [history, connection, cookies.personId, code]);

  const handleResendClick = useCallback(() => {
    if (connection && connection.bee && cookies.personId) {
      client.person.resendCode(connection.bee, cookies.personId);
    }
  }, [connection, cookies.personId]);

  const validateCode = useCallback(() => {
    return code && code.length === 4;
  }, [code]);

  const isContinueButtonDisabled = useCallback(() => {
    // Disable the continue button code is not entered
    return !validateCode(code) || validationError !== '';
  }, [validateCode, code, validationError]);

  return (
    <>
      <WrongCodeEnteredPopup show={validationError} hide={setValidationError} resendCode={handleResendClick} phone={cookies.userInfo.phone}/>
      <Grid container direction="column" justify="center" spacing={3}>
        <Typography style={{marginTop: '30px', fontWeight:'500 '}} className={classes.txt} variant="subtitle1">{strings.sent}</Typography>
        <Typography style={{marginBottom: '5px', marginTop: '50px', fontStyle:'italic'}} className={classes.txt} variant="subtitle1">{strings.enterCode}</Typography>
        <OtpInput
          shouldAutoFocus={true}
          focusStyle={{border: 'none'}}
          isInputNum={true}
          containerStyle={{justifyContent: 'center', width: '100%'}}
          inputStyle={{ fontSize: "35px",width: "60px", borderRadius: '13px', height: '80px', outline: 'none'}}
          value={code}
          onChange={handleChangeCode}
          numInputs={4}
          separator={<span style={matches? {width:'5px'} : {width: '20px'}}></span>}
        />
        <Typography style={{marginTop: '50px'}} className={classes.txt} variant="subtitle1">{strings.noCode}</Typography>
        <Grid className={classes.linkContainer} container direction="row" justify="center">
          <Link onClick={handleResendClick} className={classes.link} color="secondary" underline="always" >{strings.resendCode}</Link>
        </Grid>
        <Grid className={classes.buttonContainer} container direction="row" justify="center" spacing={1}>
          <Button disabled={isContinueButtonDisabled()} onClick={handleValidateClick} size="large" style={{textTransform: 'none'}} variant="contained" color="primary">
            {strings.continue}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
