const _ = require('lodash');
const { Observable } = require("rxjs");
const { share } = require('rxjs/operators');
const { ConnectionService, utils } = require('hive-client-utils');
const { cleanupStats } = require("./utils");

const fetchStats = async (data) => {
  return data.bee.actions.invoke('traceq.fetchStats', data.locationId)
    .then(utils.waitForInvocationReaction(data.bee, r => _.get(r, 'details.message')))
    .then(cleanupStats)
    .then(stats => data.subscriber.next(stats))
  ;
};

const handleChanges = (data) => () => {
  fetchStats(data).catch((error) => {
    console.error('Could not read stats, could have been deleted', error);
  });
};

const listenForChanges = (data) => () => {
  if (data.unsubscribed) {
    return;
  }

  const callbackId = data.bee.reactions.setCallback(
    `invocation:statsChanged_${data.locationId}`,
    handleChanges(data)
  );

  console.debug(`Adding callback for stats for location ${data.locationId}: ${callbackId}`);
  data.callbackIds.push(callbackId);
};

const setupObservable = (data) => fetchStats(data)
  .then(listenForChanges(data))
  .catch((error) => {
    console.error('Subscribing error', error);
    ConnectionService.handleBeeError(error);
    data.subscriber.error(error);
    removeCallbacks(data);
  })
  .finally(() => {
    if (data.unsubscribed) {
      removeCallbacks(data);
    }
  })
;

const tearDown = (data) => () => {
  console.debug(`Unsubscribing from stats for location ${data.locationId}...`);

  removeCallbacks(data);
  data.unsubscribed = true;

  console.debug(`Unsubscribed from stats for location ${data.locationId}.`);
};

const removeCallbacks = (data) => {
  const callbackIds = data.callbackIds;
  data.callbackIds = [];
  console.debug(`Removing callbacks for stats for location ${data.locationId}:`, callbackIds);
  callbackIds.forEach(data.bee.reactions.removeCallback);
};

function observe(bee, locationId) {

  const subcribeFn = (subscriber) => {
    console.debug(`Subscribing to stats for location ${locationId}`);

    const data = {
      bee,
      locationId,
      subscriber,
      unsubscribed: false,
      callbackIds: [],
    };

    setupObservable(data)
      .then(() => console.debug('Subscribed to stats for location ${locationId}'))
      .catch(error => {
        console.error(`Could not subscribe to stats for location ${locationId}`, error);
        subscriber.error(error);
      });

    return tearDown(data);
  };

  return new Observable(subcribeFn).pipe(share());
}

module.exports = {
  observe,
};
