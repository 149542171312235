import React, { useEffect, useState, useRef, useCallback } from "react";

import { makeStyles , withStyles} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Slider from '@material-ui/core/Slider';
import { IconButton, Typography, Container, InputBase } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useOnClickOutside } from "../utils/Hooks";
import {strings} from "../localizedStrings";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    justifyContent: "center",
    height: "70px",
    borderRadius: '0 0 6px 6px !important',
    borderRight: '2px solid grey !important',
    borderBottom: '2px solid grey !important',
    borderLeft: '2px solid grey !important',
    backgroundColor: "rgba(0,0,0,0.6)",
  },
  search: {
    flex: 1,
    margin: "12px 12px 12px 0",
  },
  searchInput: {
    border: "2px solid grey",
    borderRadius: "6px !important"
  },
  hideComponent: {
    display: "none"
  },
  filterMethodIcon: {
    color: "white",
  },
  filterMethodButton: {
    outline: '0 !important',
    ':focus': {
      outline: '0 !important'
    }
  },
  searchRadiusSliderGrid: {
    flex: 1,
    padding: theme.spacing(0,1.5,0,0),
  },
  searchRadiusContainer: {
    padding: 0,
    flexGrow: 0,
    flexShrink: 0,
  },
  txtContainer: {
    justifyContent: "center",
    width: "60px",
    margin: 0,
    boxStyle: "border-box",
  },
  txt: {
    fontWeight: '500',
    textAlign: 'center',
    color: 'white',
    fontStyle: 'bold',
  },
}));

const FilterMethodButton = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
    boxStyle: "border-box",
  },
  label: {
    background: theme.palette.primary.main + '!important',
    height: '43px !important',
    width: '43px !important',
    padding: 0,
    borderRadius: '25%'
  },
}))(IconButton);

const SearchRadiusSlider = withStyles({
  root: {
    padding: 0
  },
  markLabel: {
    color: "rgba(255,255,255, 0.7)",
  },
  markLabelActive: {
    color: "white"
  }
})(Slider);

export default function SearchLocation(props) {
  const [searchVisible, toggleSearch] = useState(false);
  const [searchRadiusVisible, toggleSearchRadius] = useState(false);
  const classes = useStyles();
  const searchRef = useRef();

  const handleSearchClick = useCallback(() => {
    if (searchVisible) {
      toggleSearch(false);
      toggleSearchRadius(false);
    } else {
      toggleSearch(true);
      toggleSearchRadius(false);
    }
  }, [searchVisible]);

  const handleSearchRadiusClick = useCallback(() => {
    if (searchRadiusVisible) {
      toggleSearchRadius(false);
      toggleSearch(false);
    } else {
      toggleSearchRadius(true);
      toggleSearch(false);
    }
  }, [searchRadiusVisible]);

  const closeAll = useCallback(() => {
    if (searchRadiusVisible || searchVisible){
      toggleSearch(false);
      toggleSearchRadius(false);
    }
  }, [searchVisible, searchRadiusVisible]);

  useEffect(() => {
    if (searchVisible && searchRef && searchRef.current) {
      searchRef.current.focus();
    }
  }, [searchVisible, searchRef]);

  useOnClickOutside(searchRef, closeAll);

  return (
    <Grid className={classes.container} container direction="row">
      <FilterMethodButton className={searchRadiusVisible? classes.hideComponent: classes.filterMethodButton} onClick={handleSearchClick}>
        <SearchIcon className={classes.filterMethodIcon} fontSize="large"/>
      </FilterMethodButton>
      <FilterMethodButton className={searchVisible? classes.hideComponent: props.locationAllowed? classes.filterMethodButton: classes.hideComponent} onClick={handleSearchRadiusClick}>
        <LocationOnIcon className={classes.filterMethodIcon} fontSize="large"/>
      </FilterMethodButton>
      <InputBase
        onChange={props.handleSearchChange}
        inputRef={searchRef}
        className={searchVisible ? classes.search : classes.hideComponent}
        variant="standard"
        placeholder={strings.searchVenuesPlaceholder}
        margin="dense"
        classes={{
          input: classes.searchInput
        }}
      />
      <Grid className={props.locationAllowed && searchRadiusVisible? classes.searchRadiusSliderGrid: classes.hideComponent} container direction="row">
        <SearchRadiusSlider
          defaultValue = {5000}
          step={1000}
          valueLabelDisplay="off"
          max={25000}
          min={0}
          onChangeCommitted={props.handleSearchRadiusChange}
        />
      </Grid>
      <Container className={props.locationAllowed && !searchVisible? classes.txtContainer: classes.hideComponent}>
        <Typography className={props.locationAllowed && !searchVisible? classes.txt: classes.hideComponent}>
          {props.currentSearchRadius === 100? "100m": props.currentSearchRadius/1000 + "km"}
        </Typography>
      </Container>
    </Grid>
  );
}

